import { createContext, useState } from "react";

export const BombCountdownContext = createContext();

export function BombCountdownProvider({children}) {
	const [isBombTime, setIsBombTime] = useState(false);

	return (
		<BombCountdownContext.Provider value={{ isBombTime, setIsBombTime }}>
			{children}
		</BombCountdownContext.Provider>
	);
}
