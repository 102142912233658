import { bscTestnet, base } from "@reown/appkit/networks";
import { isTestnetPath } from "../lib/helper.js";

const chains = [
	{
		id: 97,
		hex: "0x61",
		url: "https://data-seed-prebsc-1-s1.binance.org:8545",
		name: "Binance Smart Chain Testnet",
		nameId: "BSCT",
		currency: {
			name: "Testnet Binance Coin",
			symbol: "tBNB",
			decimals: 18,
		},
		blockExplorer: "https://testnet.bscscan.com",
		lpSymbol: "CAKE-LP",
		lpUrl: "https://pancakeswap.finance?chain=bscTestnet",
		wrappedContract: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
		moralisIdIndex: "bsc testnet",
		moralisIdNode: "bsc-testnet",
		backendId: "bsc-testnet",
		dextoolsId: "bnb", // dextools does not support testnet
		reownNetwork: bscTestnet,
		isTestnet: true
	},
	{
		id: 8453,
		hex: "0x2105",
		url: "https://base-pokt.nodies.app",
		name: "Base",
		nameId: "BASE",
		currency: {
			name: "Ethereum",
			symbol: "ETH",
			decimals: 18,
		},
		blockExplorer: "https://basescan.org",
		lpSymbol: undefined,
		lpUrl: undefined,
		wrappedContract: "0x4200000000000000000000000000000000000006",
		moralisIdIndex: "base",
		moralisIdNode: "base",
		backendId: "BASE",
		dextoolsId: "base",
		reownNetwork: base,
		isTestnet: false
	}
];

export function getChains() {
	let filteredChains = chains;

	if (!isTestnetPath()) {
		filteredChains = filteredChains.filter(chain => chain.id !== 97);
	}

	return filteredChains;
}

export function getChain(nameId) {
	return chains.find(chain => chain.nameId === nameId);
}

export function getChainById(id) {
	return chains.find(chain => chain.id === id);
}