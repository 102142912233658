import { useState, useContext } from "react";
import { useAppKitNetwork } from "@reown/appkit/react";
import styles from "./ChainDropdown.module.scss";
import SortArrow from "../../common/others/SortArrow.js";
import { ConnectionContext } from "../../../components-helper/contexts/ConnectionProvider.js";
import { conc, cond } from "../../../lib/wrapper/html.js";

import { getChains } from "../../../data/chains.js";
import { projectName } from "../../../data/project.js";

export default function ChainDropdown() {
	const { switchNetwork } = useAppKitNetwork();

	const [isChainsOpen, setIsChainsOpen] = useState(false);
	const [isChainImage, setIsChainImage] = useState(true);

	const { isConnected, chainId, connectedChain } = useContext(ConnectionContext);

	// ---- FUNCTIONS (CLICK HANDLERS) ----

	function handleSwitchChainButton(chain) {
		if (isConnected === true) {
			switchNetwork(chain.reownNetwork);
		}
	}

	// ---- FUNCTIONS (HTML ELEMENTS) ----

	function getChainButtons() {
		let chains = getChains();

		const chainButtons = chains.map((chain, index) => {
			let isDisabled = (isConnected !== true) || (chainId === chain.id);
			let title = (isConnected !== true) ? "please connect your wallet first" : undefined;

			return (
				<button key={index} className={conc(styles.chain_button, styles.chain_image_container)} onClick={() => handleSwitchChainButton(chain)} disabled={isDisabled} title={title}>
					<img className={styles.chain_image} src={"/images/chains/" + chain.nameId + ".svg"} alt={(chain.name + " (" + chain.nameId + ") chain for " + projectName)}/>
					<span className={styles.dropdown_chain_text}>{chain.nameId}</span>
				</button>
			);
		});

		return chainButtons;
	}

	return (
		<div className={styles.container} onMouseLeave={() => setIsChainsOpen(false)}>
			<button className={conc(styles.dropdown_button, cond(isChainsOpen, styles.dropdown_button_active))} onMouseEnter={() => setIsChainsOpen(true)} onClick={() => setIsChainsOpen(!isChainsOpen)}>
				{
					connectedChain !== undefined
					?
						<div className={styles.chain_image_container}>
							{
								isChainImage === true
								?
									<img className={styles.dropdown_chain_image} src={"/images/chains/" + connectedChain + ".svg"} alt={connectedChain + " chain for " + projectName} onError={() => setIsChainImage(false)}/>
								:
									<span className={styles.dropdown_chain_text}>{connectedChain}</span>
							}
						</div>
					:
						<SortArrow isUp={isChainsOpen} isSmall={false} marginClasses={cond(isChainsOpen, styles.sort_arrow_up, styles.sort_arrow_down)}/>
				}
			</button>
			{
				isChainsOpen &&
				<div className={styles.chain_button_container}>
					{getChainButtons()}
				</div>
			}
		</div>
	);
}