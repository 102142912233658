import { useEffect } from "react";
import styles from "./LoadingScreenLanding.module.scss";

import backgroundImage from "../../../images/animated_background_small.gif";

import { projectName } from "../../../data/project.js";

export default function LoadingScreenLanding() {
	
	// ---- HOOKS ----

	useEffect(() => {
		document.body.style.backgroundImage = "url(" + backgroundImage + ")";
	
		return () => {
			document.body.style.backgroundImage = "";
		};
	}, []);

	return (
		<img className={styles.image} src={"/images/decorations/heart_flip_small.gif"} alt={projectName + " - Loading Heart"}/>
	);
}