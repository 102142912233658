import { useState, useEffect, useContext } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import styles from "./BombBanner.module.scss";
import { BombCountdownContext } from "../../components-helper/contexts/BombCountdownProvider.js";
import { TokenPricesContext } from "../../components-helper/contexts/TokenPricesProvider.js";
import { defaultChain } from "../../lib/web3/web3Base.js";
import { stakeSymbol } from "../../lib/web3/web3BaseAdd.js";

import { projectName, bombAmount } from "../../data/project.js";
import { getDollarFormat } from "../../lib/helper.js";

export default function BombBanner() {
	const [price, setPrice] = useState(0);

	const { isBombTime, setIsBombTime } = useContext(BombCountdownContext);
	const { tokenPrices } = useContext(TokenPricesContext);

	// ---- HOOKS ----

	useEffect(() => {
		if (tokenPrices !== undefined) {
			let mainTokenPrice = tokenPrices[defaultChain][stakeSymbol];
			let price = mainTokenPrice * bombAmount;
			
			setPrice(price);
		}
	}, [isBombTime, tokenPrices]);

	// ---- FUNCTIONS (CLICK HANDLERS) ----

	function handleClose() {
		setIsBombTime(false);
	}

	return (
		<div className={styles.container} onClick={handleClose}>
			{
				(isBombTime && tokenPrices !== undefined) &&
				<div className={styles.inner_container}>
					<div className={styles.close_button}></div>

					<div className={styles.banner_container}>
						<div className={styles.bomb_image_container}>
							<img className={styles.bomb_image} src={"/images/decorations/complete-bomb.png"} alt={projectName + " - Bomb"}/>
							<img className={styles.bomb_image} src={"/images/decorations/complete-bomb.png"} alt={projectName + " - Bomb"}/>
							<img className={styles.bomb_image} src={"/images/decorations/complete-bomb.png"} alt={projectName + " - Bomb"}/>
						</div>
						
						<div className={styles.text_container}>
							<span>Someone just got a</span>

							<div className={styles.text_image_container}>
								<img src={"/images/decorations/luv.png"} alt={projectName + " - LUV Title"}/>
								<img src={"/images/decorations/bomb.png"} alt={projectName + " - Bomb Title"}/>
							</div>

							<span>of {bombAmount} ${stakeSymbol} (~{getDollarFormat(price)})</span>
						</div>

						<div className={styles.bomb_image_container}>
							<img className={styles.bomb_image} src={"/images/decorations/complete-bomb.png"} alt={projectName + " - Bomb"}/>
							<img className={styles.bomb_image} src={"/images/decorations/complete-bomb.png"} alt={projectName + " - Bomb"}/>
							<img className={styles.bomb_image} src={"/images/decorations/complete-bomb.png"} alt={projectName + " - Bomb"}/>
						</div>
					</div>

					<div className={styles.close_button}>
						<IoCloseCircleOutline size={30}/>
					</div>
				</div>
			}
		</div>
	);
}