import { createContext, useState, useEffect } from "react";
import { getTokenPrices } from "../../lib/web3/web3BaseAdd.js"

export const TokenPricesContext = createContext();

export function TokenPricesProvider({children}) {
	const [tokenPrices, setTokenPrices] = useState(undefined);

	// ---- HOOKS ----

	useEffect(() => {
		getTokenPrices().then(result => {
			setTokenPrices(result);
		});
	}, []);

	// ---- FUNCTIONS ----

	return (
		<TokenPricesContext.Provider value={{ tokenPrices }}>
			{children}
		</TokenPricesContext.Provider>
	);
}
