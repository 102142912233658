import { useState, useEffect } from "react";

const withSuspenseDelay = (Component, fallback, delay) => {
	return (props) => {
		const [isLoading, setIsLoading] = useState(true);

		useEffect(() => {
			const timer = setTimeout(() => {
				setIsLoading(false);
			}, delay);

			return () => clearTimeout(timer);
		}, [delay]);

		if (isLoading) {
			return fallback;
		}

		return <Component {...props}/>;
	};
};

export default withSuspenseDelay;