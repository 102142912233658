import { createContext, useState, useEffect } from "react";
import { useAppKitAccount, useAppKitProvider, useAppKitNetwork } from "@reown/appkit/react";
import { defaultChain, getChainName, getWeb3Provider, isCurrentChainSupported } from "../../lib/web3/web3Base.js";

export const ConnectionContext = createContext();

export function ConnectionProvider({children}) {
	const { walletProvider } = useAppKitProvider("eip155");
	const { address, isConnected } = useAppKitAccount();
	const { chainId } = useAppKitNetwork();
	
	const [web3Provider, setWeb3Provider] = useState(undefined);
	const [isChainSupported, setIsChainSupported] = useState(undefined);
	const [connectedChain, setConnectedChain] = useState(undefined);
	const [supportedChain, setSupportedChain] = useState(undefined);

	useEffect(() => {
		if (isConnected && chainId !== undefined && walletProvider !== undefined) {
			let isSupportedChain = isCurrentChainSupported(chainId);
			setIsChainSupported(isSupportedChain);

			getChainName(chainId).then(chain => {
				let supportedChain = isSupportedChain ? chain : defaultChain;

				setSupportedChain(supportedChain);
				setConnectedChain(chain);
			});

			setWeb3Provider(getWeb3Provider(walletProvider));
		} else {
			setIsChainSupported(false);
			setSupportedChain(defaultChain);
		}
	}, [isConnected, chainId, walletProvider]);

	return (
		<ConnectionContext.Provider value={{
			web3Provider,
			isChainSupported,
			isConnected,
			connectedChain,
			chainId,
			supportedChain,
			account: address?.toLowerCase()
		}}>
			{children}
		</ConnectionContext.Provider>
	);
}
