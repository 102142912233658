import { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import styles from "./Header.module.scss";
import InternalLink from "../../common/links/InternalLink.js";
import Background from "../../common/others/Background.js";
import Logo from "../../common/others/Logo.js";
import BombCountdown from "./BombCountdown.js";
import ChainDropdown from "./ChainDropdown.js";
import ConnectButton from "./ConnectButton.js";
import MenuButton from "./MenuButton.js";
import ReferralDropdown from "./ReferralDropdown.js";
import SearchBar from "./SearchBar.js";
import { WindowWidthContext } from "../../../components-helper/contexts/WindowWidthProvider.js";
import { conc, cond, getCssVariablePixel } from "../../../lib/wrapper/html.js";

export default function Header() {
	const mobileBreakpoint = getCssVariablePixel("--mobile-breakpoint-big");

	const location = useLocation();

	const windowWidth = useContext(WindowWidthContext);

	const [isChecked, setIsChecked] = useState(false);

	// ---- HOOKS ----

	useEffect(() => {
		resetModal();
	}, [location]);

	useEffect(() => {
		if (isChecked === true && windowWidth > mobileBreakpoint) {
			setIsChecked(false);
		}
	}, [windowWidth]);

	// ---- FUNCTIONS ----

	function resetModal() {
		setIsChecked(false);
	}

	return (
		windowWidth > mobileBreakpoint
		?
			<header className={conc(styles.header, styles.header_desktop)}>
				<div className={conc(styles.header_left_container, styles.header_left_container_desktop)}>
					<div className={styles.logo_container}>
						<Logo/>
					</div>

					<div className={styles.search_bar_container}>
						<SearchBar/>
					</div>
				</div>

				<div className={styles.header_right_container_desktop}>
					<BombCountdown/>

					<div className={styles.function_container}>
						<ConnectButton/>
						<ChainDropdown/>
						<ReferralDropdown/>
					</div>
				</div>
			</header>
		:
			<header className={conc(styles.header, styles.header_mobile)}>
				<Background isActive={isChecked} onClick={() => resetModal()}/>

				<div className={conc(styles.header_left_container, styles.header_left_container_mobile)}>
					<Logo/>
					
					<div className={styles.search_bar_container}>
						<SearchBar/>
					</div>
				</div>

				<div className={styles.header_right_container_mobile}>
					<BombCountdown/>
					<MenuButton getter={isChecked} setter={setIsChecked}/>
				</div>

				<nav className={conc(styles.nav_mobile, cond(isChecked, styles.nav_mobile_visible))}>
					<InternalLink link={"/launches"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>Launches</InternalLink>
					<InternalLink link={"/create"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>Create</InternalLink>
					<InternalLink link={"/manage"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>Manage</InternalLink>
					
					<hr/>

					<InternalLink link={"/convert"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>Convert</InternalLink>
					<InternalLink link={"/staking"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>Staking</InternalLink>
					<InternalLink link={"/dao"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>DAO</InternalLink>

					<hr/>

					{/* <InternalLink link={"/tokenomics"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>Tokenomics</InternalLink>
					<InternalLink link={"/about"} otherClasses={conc(styles.link, styles.link_mobile)} activeClasses={styles.link_active} onClick={resetModal}>About</InternalLink>

					<hr/> */}

					<div className={styles.function_container}>
						<ConnectButton/>
						<ChainDropdown/>
						<ReferralDropdown/>
					</div>
				</nav>
			</header>
	);
}