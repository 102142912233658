import { useState, useContext, useEffect } from "react";
import { CgProfile } from "react-icons/cg";
import styles from "./ReferralDropdown.module.scss";
import StringInput from "../../common/form/StringInput.js";
import ExternalLink from "../../common/links/ExternalLink.js";
import BalanceTooltip from "../../common/tokens/BalanceTooltip.js";
import { ConnectionContext } from "../../../components-helper/contexts/ConnectionProvider.js";
import { ReferralCodeContext } from "../../../components-helper/contexts/ReferralCodeProvider.js";
import { currencySymbol, getOwnReferralCode, getOwnReferralFees } from "../../../lib/web3/web3BaseAdd.js";
import { conc, cond } from "../../../lib/wrapper/html.js";

import { getSocials } from "../../../data/socials.js";

export default function ReferralDropdown() {
	const twitterLink = "https://x.com/intent/tweet?text=%40luvbombmeme%20Let%27s%20gooo%21";
	const twitterSocial = getSocials().find(social => social.link.includes("x.com"));

	const [isOpen, setIsOpen] = useState(false);

	const [ownCode, setOwnCode] = useState("");
	const [generatedFees, setGeneratedFees] = useState(undefined);

	const { isConnected, connectedChain } = useContext(ConnectionContext);
	const { referralCode, setReferralCode } = useContext(ReferralCodeContext);

	// ---- HOOKS ----

	useEffect(() => {
		refreshOwnCode();
		refreshFees();
	}, [isConnected]);

	// ---- FUNCTIONS ----

	function refreshOwnCode() {
		if (isConnected === true) {
			getOwnReferralCode().then(result => {
				setOwnCode(result);
			});
		}
	}

	function refreshFees() {
		if (isConnected === true) {
			getOwnReferralFees().then(result => {
				setGeneratedFees(result);
			});
		}
	}

	return (
		<div className={styles.container} onMouseLeave={() => setIsOpen(false)}>
			<button className={conc(styles.dropdown_button, cond(isOpen, styles.dropdown_button_active))} onMouseEnter={() => setIsOpen(true)} onClick={() => setIsOpen(!isOpen)}>
				<CgProfile size={64}/>
			</button>
			
			{
				isOpen &&
				<div className={styles.content_outer_container}>
					{
						isConnected
						?
							<div className={styles.content_container}>
								<div className={styles.content_own_container}>
									<div className={styles.content_title}>REFERRAL CODE</div>

									<div className={styles.content_own_input_container}>
										<div>Your personal referral code:</div>
										<div>
											<StringInput
												getter={ownCode}
												setter={setOwnCode}
												isOff={true}/>
										</div>
									</div>

									<div className={styles.content_own_share_container}>
										<div>Share with your friends and earn 10% of all their trading fees</div>
										
										<ExternalLink link={twitterLink} isButton={true} buttonClass={"button_green"}>
											<div className={styles.social_link_container}>
												<div>Share on</div>

												<div className={styles.social_icon_container}>
													<img className={styles.social_icon} src={twitterSocial.imagePath} alt={twitterSocial.altText}/>
												</div>
											</div>
										</ExternalLink>
									</div>

									<div className={styles.content_own_fee_container}>
										<div>Total Fees Generated:</div>

										<div>
											<BalanceTooltip balance={generatedFees}/> {currencySymbol[connectedChain]}
										</div>
									</div>
								</div>

								<div className={styles.content_other_container}>
									<div className={styles.content_title}>REFERRAL CODE</div>

									<StringInput
										getter={referralCode}
										setter={setReferralCode}
										placeholder={"Enter referral code..."}/>

									<div className={styles.content_other_description}>Enter a referral code to save 10% on all transaction fees.</div>
								</div>
							</div>
						:
							<div className={styles.content_container}>
								<div className={styles.message_container}>not connected</div>
							</div>
					}
				</div>
			}
		</div>
	);
}