import { ethers } from "ethers";

import { getChains } from "../../data/chains.js";
import { requestedChain, chainDoesNotExist } from "../../data/messages.js";

import basicTokenAbi from "../../data/abis/basicToken.json";

export const rpcProviders = getChains().reduce((rpcProviders, chain) => {
	rpcProviders[chain.nameId] = new ethers.providers.JsonRpcProvider(chain.url);
	
	return rpcProviders;
}, {});

export const defaultChain = "BSCT";

// ---- PROVIDER ----

export function getWeb3Provider(walletProvider) {
	return new ethers.providers.Web3Provider(walletProvider);
}

// ---- TX WRAPPER ----

export function interact(promise) {
	return promise
		.then(tx => {
			return tx.wait();
		})
		.catch(error => {
			if (error.code !== "ACTION_REJECTED") {
				throw error;
			} else {
				return undefined;
			}
		});
}

// ---- SEND ----

export function message(account, extraMessage) {
	return {
		from: account,
		...extraMessage
	};
}

// ---- GET EXTERNAL CHAIN DATA ----

export async function getChainName(currentChainId) {
	let chainName = undefined;

	switch (currentChainId) {
		case 97:
			chainName = "BSCT";
			break;
		case 8453:
			chainName = "BASE";
			break;
		default:
			let response = await fetch("https://chainid.network/chains.json");
			let json = await response.json();

			let chainData = json.find(chain => chain.chainId === currentChainId);

			chainName = chainData.chain;
	}

	return chainName;
}

// ---- GET INTERNAL CHAIN DATA ----

export function isCurrentChainSupported(currentChainId) {
	let supportedIds = getChains().map(chain => chain.id);

	return supportedIds.includes(currentChainId);
}

// ---- GET BALANCE ----

export async function getBalance(token, ofAddress) {
	let balance = undefined;

	if (token.contract !== undefined) {
		let tokenContract = new ethers.Contract(token.contract, basicTokenAbi, rpcProviders[token.chain.nameId]);

		balance = await tokenContract.balanceOf(ofAddress);
	} else {
		balance = await rpcProviders[token.chain.nameId].getBalance(ofAddress);
	}

	return balance;
}

export async function getOwnBalance(account, token) {
	let balance = await getBalance(token, account);
	let number = fromWei(balance, token.decimals);

	return number;
}

// ---- BIG NUMBER ----

export function toBN(number) {
	return ethers.BigNumber.from(number);
}

// ---- WEI ----

export function fromWei(wei, decimals=18) {
	let number = ethers.utils.formatUnits(wei.toString(), decimals);

	return (number === "0.0") ? "0" : number;
}

export function toWei(number, decimals=18) {
	let wei = ethers.utils.parseUnits(number, decimals);

	return wei;
}

// ---- APPROVE ----

export async function getAllowance(account, baseContract, toApproveContract) {
	let allowance = await baseContract.allowance(account, toApproveContract);

	return allowance;
}

export async function approve(account, baseContract, toApproveContract, amount) {
	let allowance = await getAllowance(account, baseContract, toApproveContract);
	let maxAmount = ethers.constants.MaxUint256;

	if (allowance < maxAmount) {
		return baseContract.approve(toApproveContract, maxAmount, message(account))
			.then(tx => {
				return tx.wait().then(() => {
					return true;
				})
			})
			.catch(error => {
				if (error.code !== "ACTION_REJECTED") {
					throw error;
				} else {
					return false;
				}
			});
	} else {
		return true;
	}
}

/*
// ---- METAMASK - CHAIN ----

export async function switchChain(chain) {
	return ethProvider.request({
		method: "wallet_switchEthereumChain",
		params: [{ chainId: chain.hex }],
	}).catch(error => {
		if (error !== undefined) {
			if (error.code === 4902 || error.data?.originalError?.code === 4902) {
				return addChain(chain);
			} else if (error.message !== undefined && error.message.includes("Chain") && error.message.includes("not approved")) {
				alert(chainDoesNotExist());
			}
		}
	});
}

async function addChain(chain) {
	return ethProvider.request({
		method: "wallet_addEthereumChain",
		params: [{
			chainId: chain.hex,
			chainName: chain.name,
			nativeCurrency: chain.currency,
			rpcUrls: [chain.url],
			blockExplorerUrls: [chain.blockExplorer],
		}],
	});
}

// ---- METAMASK - TOKEN ----

export async function addTokenToMetamask(token) {
	return watchAsset(token.contract, token.symbol, token.decimals);
}

export async function addLPTokenToMetamask(token) {
	return watchAsset(token.contract, token.chain.lpSymbol, token.decimals);
}

async function watchAsset(contract, symbol, decimals) {
	return ethProvider.request({
		method: "wallet_watchAsset",
		params: {
			type: "ERC20",
			options: {
				address: contract,
				symbol: symbol,
				decimals: decimals
			},
		},
	});
}
*/