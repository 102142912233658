export const contracts = {
	BSCT: {
		standardFactory: "0x81d58215ad336024Ee9e701202d9BC82b8ABFf1E",
		feeFactory: "0x61F7F89D638bC42B8200032eDB07FF30878944C7",
		clogFactory: "0x3895e40eb98BdaaE0B4c94077507d0100e8D487b",
		ethLauncher: "0xD143ff4f8db1CF409BB7F506ffb94F7bC22AecCB",
		stakingNft: "0x2b3AAd4129779a2B605312D4cB935042EF5A2737",
		swapper: "0x01cAc4517C69CF951e1365C72961e33cC4fa5070",
		router: "0xD99D1c33F9fC3444f8101754aBC46c52416550D1"
	},
	BASE: {
		standardFactory: "0x9beB178364075385Fba8aA3E109760F7fCAF3a7e",
		feeFactory: "0x9ddC47924eB7A1D0e404cC9571Bd39A18900a794",
		clogFactory: "0x0d9eB3749a4A968c499c26496dD91c6a0Ecc1483",
		ethLauncher: "0xA98f6d211C369E1147C1E972d92A0e34900381EA",
		stakingNft: "0xe535b798aA8778d0F110277c627303B3e12fd3a2",
		swapper: "0xb80cD114F47c1f8ef1eF2a3f15dB94EF398B4d71",
		router: "0x4752ba5dbc23f44d87826276bf6fd6b1c372ad24"
	},
};