import styles from "./Logo.module.scss";
import InternalLink from "../links/InternalLink.js";

import { projectName } from "../../../data/project.js";

export default function Logo() {
	return (
		<InternalLink link={"/"} otherClasses={styles.logo_link}>
			<img className={styles.logo_image} src={"/images/decorations/luvbomblogo.png"} alt={projectName + " - Text Logo"}/>
		</InternalLink>
	);
}