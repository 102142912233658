import { Ethers5Adapter } from "@reown/appkit-adapter-ethers5";
import { getChains } from "./data/chains.js";

export const projectId = process.env.REACT_APP_REOWN_PROJECT_ID;

export const metadata = {
	name: "AppKit",
	description: "AppKit Example",
	url: "https://reown.com",
	icons: ["https://avatars.githubusercontent.com/u/179229932"]
};

export const networks = getChains().map(chain => chain.reownNetwork);

export const ethers5Adapter = new Ethers5Adapter();
