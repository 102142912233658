import { useState, useEffect, useContext } from "react";
import styles from "./BombCountdown.module.scss";
import { BombCountdownContext } from "../../../components-helper/contexts/BombCountdownProvider.js";

import { projectName, bombIntervalMinutes, bombStartDate } from "../../../data/project.js";

export default function BombCountdown() {
    let initialTimeLeft = calculateTimeLeft(bombStartDate, bombIntervalMinutes);

	const [remainingTime, setRemainingTime] = useState(initialTimeLeft);

	const { setIsBombTime } = useContext(BombCountdownContext);

	// ---- HOOKS ----

	useEffect(() => {
		let timer = setInterval(() => {
			let updatedTimeLeft = calculateTimeLeft(bombStartDate, bombIntervalMinutes);

			setRemainingTime(updatedTimeLeft);
		}, 1000);

		return () => clearInterval(timer);
	}, []);

	useEffect(() => {
		if (remainingTime.hours === 0 && remainingTime.minutes === 0 && remainingTime.seconds === 0) {
            setIsBombTime(true);
        }
	}, [remainingTime]);

	// ---- FUNCTIONS (HELPERS) ----

	function calculateTimeLeft(startDate, intervalMinutes) {
		let now = new Date();
		let elapsedTime = now - new Date(startDate);
		let intervalMs = intervalMinutes * 60 * 1000;
		let remainingMs = intervalMs - (elapsedTime % intervalMs);

		if (remainingMs <= 0) {
			return { hours: 0, minutes: 0, seconds: 0 };
		}

		let hours = Math.floor((remainingMs / (1000 * 60 * 60)));
		let minutes = Math.floor((remainingMs / (1000 * 60)) % 60);
		let seconds = Math.floor((remainingMs / 1000) % 60);

		return { hours, minutes, seconds };
	}

	function getLeftDigit(number) {
		return Math.floor(number / 10);
	}

	function getRightDigit(number) {
		return number % 10;
	}

	return (
		<div className={styles.container}>
			<img className={styles.bomb_image} src={"/images/decorations/cutoff_bomb.png"} alt={projectName + " - Bomb"}/>

			<div className={styles.countdown_container}>
				<div className={styles.countdown_element}>
					<div className={styles.countdown_element_title}>HOURS</div>
					
					<div className={styles.countdown_element_time}>
						<div>{getLeftDigit(remainingTime.hours)}</div>
						<div>{getRightDigit(remainingTime.hours)}</div>
					</div>
				</div>

				<div className={styles.countdown_separator}>:</div>

				<div className={styles.countdown_element}>
					<div className={styles.countdown_element_title}>MINUTES</div>
					
					<div className={styles.countdown_element_time}>
						<div>{getLeftDigit(remainingTime.minutes)}</div>
						<div>{getRightDigit(remainingTime.minutes)}</div>
					</div>
				</div>

				<div className={styles.countdown_separator}>:</div>

				<div className={styles.countdown_element}>
					<div className={styles.countdown_element_title}>SECONDS</div>
					
					<div className={styles.countdown_element_time}>
						<div>{getLeftDigit(remainingTime.seconds)}</div>
						<div>{getRightDigit(remainingTime.seconds)}</div>
					</div>
				</div>
			</div>
		</div>
	);
}