
import { ethers } from "ethers";

import { fromWei, rpcProviders } from "./web3Base.js";
import { getAllTokens, getTokenImageLink, getChatMessages } from "../api/backend.js";
import { isZeroAddress } from "../helper.js";

import { getChains } from "../../data/chains.js";
import { contracts } from "../../data/contracts.js";
import { getToken } from "../../data/tokens.js";

import standardTokenAbi from "../../data/abis/standardToken.json";
import standardFactoryAbi from "../../data/abis/standardFactory.json";
import feeTokenAbi from "../../data/abis/feeToken.json";
import routerAbi from "../../data/abis/router.json";

export const stakeSymbol = "LUV";
export const unstakeSymbol = "freeLUV";
export const dollarSymbol = "USDC";
export const currencySymbol = {
	BSCT: "BNB",
	BASE: "ETH"
};

export const zeroAddress = "0x000000000000000000000000000000000000dEaD";

export const tokenLimits = {
	name: 30,
	symbol: 6,
	desription: 200,
	url: 200
};

export const fileTypes = ["image/jpeg", "image/png", "image/gif"];
export const maxFileSize = 1 * 1024 * 1024;

// ---- GETTER - ALL TOKENS ----

function removeDuplicateTokens(tokens) {
	const uniqueMap = new Map();

	tokens.forEach(item => {
		if (!uniqueMap.has(item.id)) {
			uniqueMap.set(item.id, item);
		}
	});

	return Array.from(uniqueMap.values());
}

function transformMetadata(metadata) {
	return (metadata === "-") ? "" : metadata;
}

export async function getCreatedTokens() {
	let chains = getChains();
	let allTokens = (await getAllTokens()).tokens;

	let types = {
		ERC20: "standard",
		FEE: "fee",
		CLOG: "clog"
	};

	let filteredTokens = allTokens.filter(token => token.token_type !== "LUV");
	filteredTokens = removeDuplicateTokens(filteredTokens);

	let promises = filteredTokens.map(async token => {
		let chain = chains.find(chain => chain.backendId === token.chain);
		let contract = token.contract_address.toLowerCase();
		let pairContract = token.pair_address.toLowerCase();
		let type = types[token.token_type];

		let basicData = {
			name: token.name.toUpperCase(),
			symbol: token.symbol.toUpperCase(),
			decimals: parseInt(token.decimals)
		};

		let socialData = {
			mediaAccount: token.mediaAccount.toLowerCase(),
			uri: transformMetadata(token.metadata_uri),
			description: transformMetadata(token.metadata_description),
			logo: transformMetadata(token.metadata_logo),
			website: transformMetadata(token.metadata_website),
			telegram: transformMetadata(token.metadata_telegram),
			twitter: transformMetadata(token.metadata_twitter),
			discord: transformMetadata(token.metadata_discord),
		};

		let typeData = (type !== "standard") ? { [type + "Account"]: token.feeAccount.toLowerCase() } : undefined;

		let financeData = {
			marketCap: token.market_cap,
			volume: token.volume_24h
		};

		let chatMessages = token.chatMessages;

		let imageLink = getImageLink(chain, contract, socialData.logo);
		let tokenLink = "/token/" + chain.nameId + "/" + contract;
		let tokenEditLink = "/token/" + chain.nameId + "/" + contract + "/edit";

		let creationDate = token.created_at;

		return {
			databaseId: token.id,
			contract: contract,
			pairContract: pairContract,
			type: type,
			imageLink: imageLink,
			tokenLink: tokenLink,
			tokenEditLink: tokenEditLink,
			creationDate: creationDate,
			chain: chain,
			basicData: basicData,
			socialData: socialData,
			typeData: typeData,
			financeData: financeData,
			chatMessages: chatMessages
		};
	});
	
	let tokens = await Promise.all(promises);

	return tokens;
}

export async function getNewTokenData(chain, contract) {
	return Promise.all([
		getPairContract(chain, contract, "standard"),
		getPairContract(chain, contract, "fee"),
		getPairContract(chain, contract, "clog"),
		getBasicData(chain, contract),
		getSocialData(chain, contract),
		getFinanceData(chain, contract)
	]).then(async results => {
		let pairContractStandard = results[0];
		let pairContractFee = results[1];
		let pairContractClog = results[2];

		let type = undefined;
		let pairContract = undefined;

		if (!isZeroAddress(pairContractStandard)) {
			type = "standard";
			pairContract = pairContractStandard;
		}
		if (!isZeroAddress(pairContractFee)) {
			type = "fee";
			pairContract = pairContractFee;
		}
		if (!isZeroAddress(pairContractClog)) {
			type = "clog";
			pairContract = pairContractClog;
		}

		let basicData = results[3];
		let socialData = results[4];
		let financeData = results[5];

		let imageLink = getImageLink(chain, contract, socialData.logo);
		let tokenLink = "/token/" + chain.nameId + "/" + contract;
		let tokenEditLink = "/token/" + chain.nameId + "/" + contract + "/edit";

		let creationDate = undefined;

		return getTypeData(chain, contract, type).then(result => {
			let typeData = result;

			return {
				databaseId: undefined,
				contract: contract.toLowerCase(),
				pairContract: pairContract.toLowerCase(),
				type: type,
				imageLink: imageLink,
				tokenLink: tokenLink,
				tokenEditLink: tokenEditLink,
				creationDate: creationDate,
				chain: chain,
				basicData: basicData,
				socialData: socialData,
				typeData: typeData,
				financeData: financeData,
				chatMessages: undefined
			};
		});
	});
}

async function getPairContract(chain, contract, type) {
	let factoryContractAddress = contracts[chain.nameId][type + "Factory"];
	let factoryContract = new ethers.Contract(factoryContractAddress, standardFactoryAbi, rpcProviders[chain.nameId]);

	return factoryContract.createdTokenPair(contract);
}

async function getBasicData(chain, contract) {
	let tokenContract = new ethers.Contract(contract, standardTokenAbi, rpcProviders[chain.nameId]);

	return Promise.all([
		tokenContract.name(),
		tokenContract.symbol(),
		tokenContract.decimals()
	]).then(results => {
		return {
			name: results[0].toUpperCase(),
			symbol: results[1].toUpperCase(),
			decimals: parseInt(results[2])
		}
	});
}

async function getSocialData(chain, contract) {
	let tokenContract = new ethers.Contract(contract, standardTokenAbi, rpcProviders[chain.nameId]);

	return Promise.all([
		tokenContract.metadata()
	]).then(results => {
		let metadata = results[0];

		return {
			mediaAccount: metadata.mediaAccount_.toLowerCase(),
			uri: metadata.uri_,
			description: metadata.description_,
			logo: metadata.logo_,
			website: metadata.website_,
			telegram: metadata.telegram_,
			twitter: metadata.twitter_,
			discord: metadata.discord_,
		};
	});
}

async function getTypeData(chain, contract, type) {
	if (type !== "standard") {
		let tokenContract = new ethers.Contract(contract, feeTokenAbi, rpcProviders[chain.nameId]);

		return Promise.all([
			tokenContract.feeAccount()
		]).then(results => {
			return {
				[type + "Account"]: results[0].toLowerCase(),
			};
		});
	} else {
		return undefined;
	}
}

async function getFinanceData(chain, contract) {
	return Promise.all([
		getMarketCap(chain, contract),
		getVolume(chain, contract)
	]).then(results => {
		return {
			marketCap: results[0],
			volume: results[1]
		};
	});
}

// ---- GETTER - SPECIFIC TOKEN ----

export function getImageLink(chain, tokenContract, logo) {
	let link = undefined;

	let acceptedFileTypes = fileTypes.map(type => type.split("/")[1]);

	if (acceptedFileTypes.includes(logo)) {
		link = getTokenImageLink(chain.nameId, tokenContract, logo);
	} else {
		link = "/images/decorations/default.png";
	}

	return link;
}

export async function getPrice(chain, contract, tokenPrices) {
	let routerContract = new ethers.Contract(contracts[chain.nameId].router, routerAbi, rpcProviders[chain.nameId]);

	let mainToken = getToken(chain.nameId, stakeSymbol);
	let currencyToken = getToken(chain.nameId, currencySymbol[chain.nameId]);

	let currencyPrice = tokenPrices[chain.nameId][currencyToken.symbol];

	let inputAmount = "1000000000000000000";

	let path1 = [mainToken.contract, currencyToken.wrappedContract];
	let amount1Wei = await routerContract.getAmountsOut(inputAmount, path1);
	let amount1 = fromWei(amount1Wei[1], currencyToken.decimals);

	let path2 = [contract, mainToken.contract];
	let amount2Wei = await routerContract.getAmountsOut(inputAmount, path2);
	let amount2 = fromWei(amount2Wei[1], mainToken.decimals);

	let price1 = currencyPrice * amount1;
	let price2 = price1 * amount2;

	return price2;
}

export async function getMarketCap(chain, contract) {
	return undefined;
}

export async function getVolume(chain, contract) {
	return undefined;
}

// ---- REFERRAL ----

export async function getOwnReferralCode() {
	return "MatzeForPresident";
}

export async function getOwnReferralFees() {
	return "10";
}

// ---- OUR TOKEN PRICES ----

export async function getTokenPrices() {
	let chains = getChains().map(chain => chain.nameId);

	let tokenPrices = {};
	let inputAmount = "1000000000000000000";

	for (const chain of chains) {
		let routerContract = new ethers.Contract(contracts[chain].router, routerAbi, rpcProviders[chain]);

		let mainToken = getToken(chain, stakeSymbol);
		let currencyToken = getToken(chain, currencySymbol[chain]);
		let dollarToken = getToken(chain, dollarSymbol);

		let path1 = [dollarToken.contract, currencyToken.wrappedContract];
		let amount1Wei = await routerContract.getAmountsOut(inputAmount, path1);
		let amount1 = fromWei(amount1Wei[1], currencyToken.decimals);

		let path2 = [currencyToken.wrappedContract, mainToken.contract];
		let amount2Wei = await routerContract.getAmountsOut(amount1Wei[1], path2);
		let amount2 = fromWei(amount2Wei[1], mainToken.decimals);

		tokenPrices[chain] = {};
		tokenPrices[chain][currencySymbol[chain]] = amount1;
		tokenPrices[chain][stakeSymbol] = amount2;
	}

	return tokenPrices;
}