import { getChain } from "./chains.js";
import { isTestnetPath } from "../lib/helper.js";

const basePath = "/images/tokens/";

const tokens = {
	BSCT: [
		{
			symbol: "BNB",
			wrappedContract: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
			decimals: 18,
			coingeckoId: "binancecoin",
			imagePath: basePath + "BNB.svg"
		},
		{
			symbol: "USDC",
			contract: "0x64544969ed7EBf5f083679233325356EbE738930",
			decimals: 18,
			coingeckoId: "usd-coin",
			imagePath: basePath + "USDC.svg"
		},
		{
			symbol: "USDT",
			contract: "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
			decimals: 18,
			coingeckoId: "tether",
			imagePath: basePath + "USDT.svg"
		},
		{
			symbol: "LUV",
			contract: "0x65759750380dcd2a72166dFa88D45Fb73B70BC55",
			decimals: 18,
			coingeckoId: undefined,
			imagePath: basePath + "LUV.svg"
		},
		{
			symbol: "freeLUV",
			contract: "0x9D3FB4C73F9F60b4BbB4d6D8B8D94267451249a8",
			decimals: 18,
			coingeckoId: undefined,
			imagePath: basePath + "LUV.svg"
		},
	],
	BASE: [
		{
			symbol: "ETH",
			wrappedContract: "0x4200000000000000000000000000000000000006",
			decimals: 18,
			coingeckoId: "ethereum",
			imagePath: basePath + "ETH.svg"
		},
		{
			symbol: "USDC",
			contract: "0x833589fcd6edb6e08f4c7c32d4f71b54bda02913",
			decimals: 6,
			coingeckoId: "usd-coin",
			imagePath: basePath + "USDC.svg"
		},
		{
			symbol: "USDT",
			contract: "0xfde4C96c8593536E31F229EA8f37b2ADa2699bb2", // MATZE
			decimals: 6,
			coingeckoId: "tether",
			imagePath: basePath + "USDT.svg"
		},
		{
			symbol: "LUV",
			contract: "0xb5dD21036B1756Fcd493EE388dB72327cbA5c9f7",
			decimals: 18,
			coingeckoId: undefined,
			imagePath: basePath + "LUV.svg"
		},
		{
			symbol: "freeLUV",
			contract: "0x07EA26B60960D3d2C07C4D6253Ca71D27daB9726",
			decimals: 18,
			coingeckoId: undefined,
			imagePath: basePath + "LUV.svg"
		},
		{
			symbol: "LUV-USD",
			contract: "0xB87f3e568FB950EAE1001681712E1f3559048D06",
			decimals: 18,
			coingeckoId: undefined,
			imagePath: basePath + "LUV.svg"
		},
	]
};

// ---- GET ALL TOKENS ----

export function getAllTokens() {
	let allTokens = [];
	let currentTokens = tokens;

	if (!isTestnetPath()) {
		delete currentTokens.BSCT;
	}

	for (const [chainName, tokens] of Object.entries(currentTokens)) {
		let chain = getChain(chainName);

		let newTokens = tokens.map(token => {
			return {...token, chain: chain};
		});

		allTokens = [...allTokens, ...Object.values(newTokens)];
	}

	return allTokens;
}

// ---- GET SPECIFIC TOKEN ----

export function getToken(chain, symbol) {
	return getAllTokens().find(token => token.chain.nameId === chain && token.symbol === symbol);
}

export function getTokenByContract(chain, contract) {
	return getAllTokens().find(token => token.chain.nameId === chain && token.contract === contract);
}
