import { useAppKit } from "@reown/appkit/react";
import { useState, useContext, useEffect } from "react";
import styles from "./ConnectButton.module.scss";
import { ConnectionContext } from "../../../components-helper/contexts/ConnectionProvider.js";

export default function ConnectButton() {
	const { open } = useAppKit();
	const { account, isConnected } = useContext(ConnectionContext);

	const [connectButtonText, setConnectButtonText] = useState("CONNECT");

	// ---- HOOKS ----

	useEffect(() => {
		refreshAccount();
	}, [account]);

	// ---- FUNCTIONS ----

	function refreshAccount() {
		if (account !== undefined) {
			let accountText = account.substring(0, 6) + "..." + account.substring(account.length - 4, account.length);

			setConnectButtonText(accountText);
		}
	}

	// ---- FUNCTIONS (CLICK HANDLERS) ----

	function handleConnect() {
		if (isConnected) {
			open({ view: "Account" });
		} else {
			open();
		}
	}

	return (
		<button className={styles.connect_button} onClick={handleConnect}>
			<span>{connectButtonText}</span>
		</button>
	);
}