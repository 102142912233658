import { createContext, useState, useContext, useEffect } from "react";
import { ConnectionContext } from "./ConnectionProvider.js";
import { CreatedTokensContext } from "./CreatedTokensProvider.js";
import { isDefined } from "../../lib/helper.js";

export const OwnTokensContext = createContext();

export function OwnTokensProvider({children}) {
	const [ownTokens, setOwnTokens] = useState(undefined);

	const { isConnected, account } = useContext(ConnectionContext);
	const { createdTokens } = useContext(CreatedTokensContext);

	// ---- HOOKS ----

	useEffect(() => {
		refreshTokens();
	}, [isConnected, account, createdTokens]);

	// ---- FUNCTIONS ----

	async function refreshTokens() {
		if (isDefined(account, createdTokens)) {
			let tokens = createdTokens.map(token => {
				let isMedia = account === token.socialData.mediaAccount;
				let isFee = (token.typeData !== undefined) ? account === token.typeData.feeAccount : false;
				let isClog = (token.typeData !== undefined) ? account === token.typeData.clogAccount : false;
				
				if (isMedia || isFee || isClog) {
					return token;
				} else {
					return undefined;
				}
			});

			let filteredTokens = tokens.filter(token => token !== undefined);

			setOwnTokens([...filteredTokens]);
		}
	}

	return (
		<OwnTokensContext.Provider value={{ ownTokens }}>
			{children}
		</OwnTokensContext.Provider>
	);
}