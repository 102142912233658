import { lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createAppKit } from "@reown/appkit/react";
import "./App.scss";
import Main from "./components/structure/Main.js";
import LoadingScreenLanding from "./components/common/loading/LoadingScreenLanding.js";
import LoadingScreenOther from "./components/common/loading/LoadingScreenOther.js";
import withSuspenseDelay from "./components/common/loading/withSuspenseDelay.js";
import { BalancesProvider } from "./components-helper/contexts/BalancesProvider.js";
import { BombCountdownProvider } from "./components-helper/contexts/BombCountdownProvider.js";
import { ConnectionProvider } from "./components-helper/contexts/ConnectionProvider.js";
import { CreatedTokensProvider } from "./components-helper/contexts/CreatedTokensProvider.js";
import { OwnTokensProvider } from "./components-helper/contexts/OwnTokensProvider.js";
import { ReferralCodeProvider } from "./components-helper/contexts/ReferralCodeProvider.js";
import { TokenPricesProvider } from "./components-helper/contexts/TokenPricesProvider.js";
import { WindowWidthProvider } from "./components-helper/contexts/WindowWidthProvider.js";

import { projectId, metadata, networks, ethers5Adapter  } from "./App.config.js";

// ---- REOWN ----

createAppKit({
	adapters: [ethers5Adapter],
	networks,
	metadata,
	projectId,
	features: {
		email: false,
		emailShowWallets: false,
		socials: []
	}
});

// ---- CONFIG ----

const landingDelay = 2000;
const otherDelay = 1000;

// ---- LAZY CONTAINERS ----

const LandingContainer = lazy(() => import("./components/content/landing/LandingContainer.js"));

const CreateContainer = lazy(() => import("./components/content/create/CreateContainer.js"));
const ManageContainer = lazy(() => import("./components/content/manage/ManageContainer.js"));
const DaoContainer = lazy(() => import("./components/content/dao/DaoContainer.js"));

const SwapContainer = lazy(() => import("./components/content/swap/SwapContainer.js"));
const StakingContainer = lazy(() => import("./components/content/staking/StakingContainer.js"));
const HistoryContainer = lazy(() => import("./components/content/history/HistoryContainer.js"));

const TokenContainer = lazy(() => import("./components/content/token/TokenContainer.js"));
const TokenEditContainer = lazy(() => import("./components/content/tokenEdit/TokenEditContainer.js"));

const TokenomicsContainer = lazy(() => import("./components/content/tokenomics/TokenomicsContainer.js"));
const AboutContainer = lazy(() => import("./components/content/about/AboutContainer.js"));

const NotFoundContainer = lazy(() => import("./components/content/notFound/NotFoundContainer.js"));

// ---- DELAYED CONTAINERS ----

const DelayedLandingContainer = withSuspenseDelay(LandingContainer, <LoadingScreenLanding/>, landingDelay);

const DelayedCreateContainer = withSuspenseDelay(CreateContainer, <LoadingScreenOther/>, otherDelay);
const DelayedManageContainer = withSuspenseDelay(ManageContainer, <LoadingScreenOther/>, otherDelay);
const DelayedDaoContainer = withSuspenseDelay(DaoContainer, <LoadingScreenOther/>, otherDelay);

const DelayedSwapContainer = withSuspenseDelay(SwapContainer, <LoadingScreenOther/>, otherDelay);
const DelayedStakingContainer = withSuspenseDelay(StakingContainer, <LoadingScreenOther/>, otherDelay);
const DelayedHistoryContainer = withSuspenseDelay(HistoryContainer, <LoadingScreenOther/>, otherDelay);

const DelayedTokenContainer = withSuspenseDelay(TokenContainer, <LoadingScreenOther/>, otherDelay);
const DelayedTokenEditContainer = withSuspenseDelay(TokenEditContainer, <LoadingScreenOther/>, otherDelay);

const DelayedTokenomicsContainer = withSuspenseDelay(TokenomicsContainer, <LoadingScreenOther/>, otherDelay);
const DelayedAboutContainer = withSuspenseDelay(AboutContainer, <LoadingScreenOther/>, otherDelay);

const DelayedNotFoundContainer = withSuspenseDelay(NotFoundContainer, <LoadingScreenOther/>, otherDelay);


// ---- APP ----

export default function App() {
	return (
		<BrowserRouter>
			<ConnectionProvider>
				<BalancesProvider>
					<BombCountdownProvider>
						<CreatedTokensProvider>
							<OwnTokensProvider>
								<ReferralCodeProvider>
									<TokenPricesProvider>
										<WindowWidthProvider>
											<Routes>
												<Route path="/" element={<DelayedLandingContainer/>}/>

												<Route path="/" element={<Main/>}>
													<Route exact path="/create" element={<DelayedCreateContainer/>}/>
													<Route exact path="/manage" element={<DelayedManageContainer/>}/>
													<Route exact path="/dao" element={<DelayedDaoContainer/>}/>

													<Route exact path="/convert" element={<DelayedSwapContainer/>}/>
													<Route exact path="/staking" element={<DelayedStakingContainer/>}/>
													<Route exact path="/launches" element={<DelayedHistoryContainer/>}/>

													<Route exact path="/token/:chain/:contract" element={<DelayedTokenContainer/>}/>
													<Route exact path="/token/:chain/:contract/edit" element={<DelayedTokenEditContainer/>}/>

													{/* <Route exact path="/tokenomics" element={<DelayedTokenomicsContainer/>}/>
													<Route exact path="/about" element={<DelayedAboutContainer/>}/> */}
												</Route>

												<Route path="*" element={<DelayedNotFoundContainer/>}/>
											</Routes>
										</WindowWidthProvider>
									</TokenPricesProvider>
								</ReferralCodeProvider>
							</OwnTokensProvider>
						</CreatedTokensProvider>
					</BombCountdownProvider>
				</BalancesProvider>
			</ConnectionProvider>
		</BrowserRouter>
	);
}