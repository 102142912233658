import { createContext, useState } from "react";

export const ReferralCodeContext = createContext();

export function ReferralCodeProvider({children}) {
	const [referralCode, setReferralCode] = useState("");

	return (
		<ReferralCodeContext.Provider value={{ referralCode, setReferralCode }}>
			{children}
		</ReferralCodeContext.Provider>
	);
}
